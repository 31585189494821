/*
* Gcerevision Template

*/
/* ------------------------------------- */
/* TABLE OF CONTENTS
/* -------------------------------------
  1. IMPORT GOOGLE FONTS
  2. GENERAL
    -2.1 SECTION TITLE
    -2.2 BUTTONS
    -2.3 SOCIAL ICON
    -2.4 PRE LOADER
    -2.5 SCROLL TO UP
  3. NAVBAR
  4. INTRO SECTION
  4. HERO SECTION
  5. RESTAURANT SECTION
  6. DELIVERY SECTION
  7. FOOTER SECTION
  ------------------------------------- */
/* ==========================================================================
   1. IMPORT GOOGLE FONTS
   ========================================================================== */
body {
  font-family: "Raleway", sans-serif !important;
  font-weight: 300;
  color: #614c4b;
}
p,
h1,
h2,
h3 {
  font-family: "Raleway", serif !important;
}
.uk-navbar-nav * {
  font-family: "Raleway", serif !important;
}
.uk-h1 {
  font-family: "Raleway", serif !important;
}
.uk-h2 {
  font-family: "Raleway", serif !important;
}
.uk-card-title {
  font-family: "Raleway", serif !important;
}
.is-primary {
  color: #d83030 !important;
}
.bg-header-color {
  background-color: #fff !important;
  color: #fff;
}
.color-is-black {
  color: #000 !important;
}

.uk-navbar-nav > li {
  font-weight: 400;
}

.uk-navbar-nav > li > a {
  color: #032e59 !important;
  font-size: 12px;
}

.uk-navbar-nav > li:hover > a,
.uk-navbar-nav > li > a.uk-open {
  color: #f50021 !important;
  outline: 0;
}
.uk-text-bold {
  font-weight: 700 !important;
}
.section-primary {
  background-color: #000;
}

.color-is-darkBlue {
  color: #032e59;
}

.color-is-white {
  color: #fff !important;
}

.color-is-gray-footer {
  color: #797878 !important;
}
.background-is-black {
  background-color: #000000d6 !important;
}

.as-contact {
  padding: 8px 5px 5px 5px !important;
  font-size: 13px;
}

.as-dropdown {
  padding: 10px !important;
  margin-top: 0 !important;
  position: absolute;
  left: 20% !important;
  min-width: 100px !important;
}

.uk-overlay-primary {
  background: rgba(34, 34, 34, 0.7) !important;
}
.uk-button-danger {
  background-color: #f50021 !important;
}
.background-section-1 * {
  background-color: #191a1c !important;
  color: #fff !important;
}
.background-section-2 * {
  background-color: #d83030;
  color: #fff !important;
}
.background-section-3 {
  background-color: #fff;
}

.as-app {
  margin-bottom: -170px !important;
  position: relative;
  margin-top: 0px;
}

.as-footer {
  position: absolute;
  margin-top: 170px;
}
.as-footer-section {
  padding-top: 150px;
}

.as-footer .uk-container {
  padding-top: 50px;
}

footer {
  font-size: 12px !important;
}

.uk-navbar-item,
.uk-navbar-nav > li > a,
.uk-navbar-toggle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  min-height: 80px;
  padding: 0 5px;
  font-size: 0.875rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-decoration: none;
}
